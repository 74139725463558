<template>
  <div class="appointment-list">
    <van-pull-refresh v-model="isLoading" @refresh="onRefresh">
    <div class="appointment-list-item list-card"  v-for="(item, index) in applointmentList" :key="index" @click="$router.push('/appointment-detail/'+item.orderNo)">
      <div class="list-item-title flex">
        <span style="flex: 1" class="ove">订单单号 {{item.orderNo}}</span>
        <span class="tip" :style="{background: `url(${item.statusName != '已支付'? icon :  activeIcon}) left top/100% no-repeat `,color: item.statusName != '已支付' ? '#6E7886' : '#fff'}">{{item.statusName}}</span>
      </div>
      <div class="list-item-c flex">
        <div class="item-c-img">
          <img :src="item.productMainImageUrls[0] | filterImg" alt="">
        </div>
        <div>
          <p style="color: #131828;font-size: 17px" class="bold">{{item.hospitalName}}</p>
          <p>{{item.productName}}</p>
        </div>
      </div>
      <div class="list-item-time flex">
        <div class="icon">
          <img src="@/assets/icon_img_daojian@2x.png" alt="">
        </div>
        <span style="flex: 1">到检时间：{{item.appointmentDate.split(' ')[0]}}</span>
        <a :href="item.downloadReportUrl" download>
          <van-button type="primary" size="small" round @click.stop="download" v-if="item.downloadReportUrl">下载报告</van-button>
        </a>
      </div>
    </div>
    </van-pull-refresh>
    <van-empty description="暂无数据" v-if="!applointmentList.length" />
  </div>
</template>
<script>
import { GetAppointmentOrders} from '@/api'
export default {
  name: 'appointment-list',
  data () {
    return {
      baseUrl: process.env.VUE_APP_BASEURL || location.origin,
      applointmentList: [],
      icon: require('@/assets/icon_img_daitijian@2x.png'),
      activeIcon: require('@/assets/icon_img_daitijian@2x (1).png'),
      isLoading: false
    }
  },
  created () {
    this.$setTitle('预约记录')
    this.$store.commit('setTitle', '预约记录')
    this.getList()
  },
  methods: {
    async onRefresh () {
      this.isLoading = true
      this.getList()
      this.isLoading = false
    },
    download () {
       let a = document.createElement('a');
          a.className = 'excel_box';
          a.download = '';
          a.href = '';
          document.body.appendChild(a);
          a.click();
          document.body.removeChild(a);
          window.URL.revokeObjectURL('');
    },
    async getList () {
      let data = await GetAppointmentOrders()
      this.applointmentList = data
      this.applointmentList = this.applointmentList.map(item => {
        if (item.productMainImageUrls) {
          item.productMainImageUrls = JSON.parse(item.productMainImageUrls)
        }
        return item
      })
    }
  }
}
</script>

<style lang="scss">
.appointment-list{
  overflow-y: auto;
  padding: 15px;
  -webkit-overflow-scrolling: touch;
  .appointment-list-item{
    margin-bottom: 10px;
    .list-item-title{
      .tip{
        width: 68px;
        height: 20px;
        padding-right: 7px;
        text-align: right;
        line-height: 20px;
      }
    }
    .list-item-c{
      padding: 18px 0;
      border-bottom: 1px #DCDCDC dashed;
      font-size: 14px;
      .item-c-img{
        width: 58px;
        height: 58px;
        border-radius: 10px;
        margin-right: 17px;
      }
      p{
        line-height: 29px;
      }
    }
    .list-item-time{
      margin-top: 8px;
      align-items: center;
      font-size: 14px;
      .icon{
        width: 14px;
        margin-right: 12px;
      }
    }
  }
}
</style>